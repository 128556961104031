// Customizable Area Start
import React from 'react';
import * as yup from 'yup';
import {CheckRounded, ClearOutlined} from "@material-ui/icons"
import {Box} from "@material-ui/core";
const amountWithCommaRegex = /^(\d{1,3})(,\d{3})*(\.\d+)?$/;



interface LocationValidationArgs {
    fieldName: string;
    otherFieldName: string;
    requiredMessage: string;
    notSameMessage: string;
  }
  
  interface DateValidationArgs {
    requiredMessage: string;
  }
  
  interface DateRangeValidationArgs {
    fromDateFieldName: string;
    toDateFieldName: string;
    fromDateRequiredMessage: string;
    toDateRequiredMessage: string;
    toDateBeforeFromDateMessage: string;
  }

  const createLocationValidation = ({
    fieldName,
    otherFieldName,
    requiredMessage,
    notSameMessage,
  }: LocationValidationArgs): any => {
    return yup
      .mixed()
      .nullable()
      .required(requiredMessage)
      .test(`${fieldName}-notSame`, notSameMessage, function (value?: any) {
        
        const otherFieldValue: any = this.parent[otherFieldName];
        if (!otherFieldValue || (
          value?.city !== otherFieldValue?.city || value.code !== otherFieldValue.code)) {
          return true;
        }
        return false;
      });
  };
  
  const createDateRequiredValidation = ({
    requiredMessage,
  }: DateValidationArgs): any => {
    return yup
      .date()
      .nullable()
      .required(requiredMessage)
  };
  
  const createDateRangeSchema = ({
    fromDateFieldName,
    toDateFieldName,
    fromDateRequiredMessage,
    toDateRequiredMessage,
    toDateBeforeFromDateMessage,
  }: DateRangeValidationArgs) => {
    return {
      [fromDateFieldName]: yup.date()
        .nullable()
        .required(fromDateRequiredMessage)
        .typeError(`${fromDateFieldName} must be a valid date`),
        
      [toDateFieldName]: yup.date()
        .nullable()
        .required(toDateRequiredMessage)
        .typeError(`${toDateFieldName} must be a valid date`)
        .when(fromDateFieldName, (fromDate: Date | null, schema: yup.DateSchema) =>
          fromDate ? schema.min(fromDate, toDateBeforeFromDateMessage) : schema
        ),
    };
  };

   const aircarftSelectSchema = yup.object().shape({
        aircraftCategory: yup
          .string()
          .required("Aircraft category is required*")
          .notOneOf(
            ["Aircraft Category"],
            'Aircraft category is required*',
          ),
    })
  
  const oneWayFormValidationSchema = yup.object().shape({
    fromLocation: createLocationValidation({
      fieldName: 'fromLocation',
      otherFieldName: 'toLocation',
      requiredMessage: 'From Location is required*',
      notSameMessage: "From and To airport can't be the same*",
    }),
  
    toLocation: createLocationValidation({
      fieldName: 'toLocation',
      otherFieldName: 'fromLocation',
      requiredMessage: 'To Location is required*',
      notSameMessage: "From and To airport can't be the same*",
    }),
  
    departure: createDateRequiredValidation({
      requiredMessage: 'Departure date is required*',
    }),
  
  });

  const oneWayFormSchema = yup.object().shape({
    ...aircarftSelectSchema.fields,
    ...oneWayFormValidationSchema.fields,
  });
  

  const roundTripFormValidationSchema = oneWayFormValidationSchema.shape({
    departure: createDateRequiredValidation({
      requiredMessage: 'Return date is required*',
    }),
    leg1Departure: yup
      .date()
      .nullable()
      .required('Departure date is required**')
      .max(yup.ref('departure'), 'Departure date cannot be after the return date*'),
  });

  const roundTripSchema = yup.object().shape({
    ...aircarftSelectSchema.fields,
    ...roundTripFormValidationSchema.fields,
  });

  const multiLegFormValidationSchema = yup.object().shape({
    trips: yup.array().of(oneWayFormValidationSchema)
  });

  const multiLegSchema = yup.object().shape({
    ...aircarftSelectSchema.fields,
    ...multiLegFormValidationSchema.fields,
  });

  const contactUsValidationSchema=(key:string)=> {
    return yup.object().shape({
      [key]: yup.string()
                .matches(/^\+?[0-9\s-]+$/, "Phone number must contain only digits")
                .required('This field is required')
    });
  }


  const contact_us_validation_schema = yup.object().shape({
    phone_number: yup.string()
        .required('Call number is required*'),
    whatsapp: yup.string()
        .required('Text number is required*'),
    call_label: yup.string()
        .required('Call label is required*'),
    text_label: yup.string()
        .required('Text label is required*'),
});


const payment_summary_validation_schema = yup.object().shape({
  booking_amount:yup.string().nullable()
  .matches(amountWithCommaRegex, 'Booking amount must be valid price')
  .required('Booking amount is required*'),
  taxes:yup.string().nullable()
  .matches(amountWithCommaRegex, 'Taxes must be a number*')
  .required('Taxes are required*'),
  segment_fees: yup.string().nullable()
  .matches(amountWithCommaRegex, 'Segment fees must be a valid price*')
  .required('Segment fees are required*')
});


const order_details_schema = yup.object().shape({
  client_name: yup.object().shape({
    full_name: yup.string().nullable().required('Full name is required.'),
  }),
  account_id: yup.object().shape({
    account_number: yup.string().nullable().required('Account ID is required.'),
  }),
});

const commission_schema = yup.object().shape({
  sales_rep: yup.string().required('Sales representative is required*').typeError('Sales representative is required*'),
});
const flightIteneraryValidationSchema = yup.object().shape({
  from_airport: createLocationValidation({
    fieldName: 'fromLocation',
    otherFieldName: 'to_airport',
    requiredMessage: 'From Location is required',
    notSameMessage: "From and To airport can't be the same",
  }),

  to_airport: createLocationValidation({
    fieldName: 'toLocation',
    otherFieldName: 'from_airport',
    requiredMessage: 'To Location is required',
    notSameMessage: "From and To airport can't be the same",
  }),
  arrival: yup.date().nullable().required("Arrival date is required"),
  departure : yup
  .date()
  .nullable()
  .required('Departure date is required*')
  .max(yup.ref('arrival'), 'Departure date cannot be after the arrival date'),
  no_of_passengers: yup
  .number()
  .required('Number of passengers is required')
  .typeError('Number of passengers must be a number'),
  no_of_stops:yup.string().nullable().required('Number of stops is required'),
  vendor_charged: yup
  .string()
  .nullable()
  .matches(amountWithCommaRegex, 'Vendor charged must be a valid amount'),
})

const systemSettingsSchema = yup.object().shape({
  domestic_segment_fee: yup.number().typeError('Domestic segment fee must be a number*'),
  international_segment_fee: yup.number().typeError('International segment fee must be a number*'),
  landing_fee: yup.number().typeError('Landing fee must be a number*'),
  markup_percentage: yup.number().typeError('Markup percentage must be a number*').max(100,'Markup percentage must be a percentage figure').nullable(),
  tax: yup.number().typeError('Tax must be a number').max(100,'Tax must be a percentage figure').nullable(),
  payment_processing_fee: yup.number().typeError('Payment Processing Fee must be a number').max(100,'Payment Processing Fee must be a percentage figure').nullable()
});

const passwordTester = (
  password: string,
  isPasswordErrorsVisible: boolean,
  condition: boolean,
) => {
  if (password && condition) {
    return <CheckRounded className="register_valid_tick" />;
  }
  if (isPasswordErrorsVisible  && !password) {
    return <CheckRounded className="register_valid_grey" />;
  }
  return <ClearOutlined className="register_valid_cross" />;
};


const renderPasswordErrorBox=(value:any,isErrorPasswordContainerVisible:any)=>{
              
  return  <Box className="register_password_validation_container" padding={'0rem'} marginTop={'1rem'}>
        <Box>Password must contain:</Box>
        <Box className="regsiter_display_flex">
            <Box component={"span"} className="register_icon_container">
              {passwordTester(
                value,
                isErrorPasswordContainerVisible,
                /[A-Z]/.test(value),
              )}
            </Box>
            At least one capital letter{" "}
        </Box>
        <Box className="regsiter_display_flex">
  <Box component={"span"} className="register_icon_container">
    {passwordTester(
      value,
      isErrorPasswordContainerVisible,
      /[a-z]/.test(value),
    )}
  </Box>
  At least one lowercase letter
</Box>
<Box className="regsiter_display_flex">
  <Box component={"span"} className="register_icon_container">
    {passwordTester(
      value,
      isErrorPasswordContainerVisible,
      /\d/.test(value),
    )}
  </Box>
  At least one number
</Box>
<Box className="regsiter_display_flex">
          <Box component={"span"} className="register_icon_container">
            {passwordTester(
              value,
              isErrorPasswordContainerVisible,
              /[!@#$%^&*(),.?":{}|<>]/.test(value),
            )}
          </Box>
          At least one special character
        </Box>
<Box className="regsiter_display_flex">
  <Box component={"span"} className="register_icon_container">
    {passwordTester(
      value,
      isErrorPasswordContainerVisible,
      value.length >= 8,
    )}
  </Box>
  Minimum character length is 8 characters
</Box>
        </Box>
}

const emailValidationSchema = yup.object().shape({
  email:yup.string().email('Inavlid Email Address*').required('Email is required*')
})

const otpValidationSchema = yup.object().shape({
  otpValue : yup.string().required('OTP is required').matches(/^\d{4}$/, 'OTP must be exactly 4 digits')
})

const commissionKeySchema = yup.string()
  .trim()
  .matches(/^\d+(\.\d+)?$/, 'Commission must be a number*')
  .test('is-less-than-100', 'Commission must be less than 100%', function(value) {
    return !value || (parseFloat(value) < 100);
  });



const invoiceValidationSchema = yup.object().shape({
  invoice_name:yup.string().required('Invoice Name is required'),
  invoice_items_attributes: yup.array().of(
    yup.object().shape({
      item_name: yup.string()
        .trim()
        .matches(/.*/, "Invalid Item Name*")
        .min(1, 'Item name cannot be empty*')
        .required('Item name is required*')
        .test('is-not-only-spaces', 'Item cannot be only spaces', value => {
          return value && value.trim().length > 0;
        }),
        price: yup.string()
        .trim()
        .matches(/^\d+(\.\d+)?$/, 'Price must be a number*')
        .required('Price is required*'),
      quantity: yup.string()
        .trim()
        .matches(/^\d+(\.\d+)?$/, 'Quantity must be a number*')
        .required('Quantity is required*'),
    })
  ),
})

const expiryDateValidation = () => {
  return yup.string()
    .nullable()
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Expiry must be in MM/YY format')
    .required('Expiry is required')
    .test(
      'expiry',
      'Expiry date is past',
      function (value) {
        if (!value) return true;
        const [month, year] = value.split('/').map(Number);
        const now = new Date();
        const expiryDate = new Date(`20${year}` as unknown as number, month - 1);
        return expiryDate >= new Date(now.getFullYear(), now.getMonth(), 1);
      }
    );
};

const customSkyCardSchema=yup.object().shape({
  fromAirport: yup.object({
    id:createLocationValidation({
      fieldName: 'fromAirport',
      otherFieldName: 'toAirport',
      requiredMessage: 'From Location is required',
      notSameMessage: "From and To airport can't be the same",
    })
  }),

  toAirport: yup.object({
    id:createLocationValidation({
      fieldName: 'toAirport',
      otherFieldName: 'fromAirport',
      requiredMessage: 'To Location is required',
      notSameMessage: "From and To airport can't be the same",
    })
  }),
  noOfTrips: yup.number()
    .typeError('No of Trips must be a number')
    .required('No of Trips is required')
    .min(1, 'No of Trips cannot be empty'),
    
  price: yup.string().nullable()
  .matches(amountWithCommaRegex, 'Price must be valid number')
  .required('Price is required'),
    
  validFrom:yup.string()
    .required('From Date is required')
    .test('from-to', 'From Date must be before To Date', function(value) {
      const {validTo} = this.parent;
      if (!validTo || !value) return true;
      return new Date(value) <= new Date(validTo);
    }),
    validTo:yup.string()
    .nullable()
    .required("To Date is required")
    .test('to-after-from', 'To date must be after From date', function(value) {
      const { validFrom } = this.parent; // Accessing the value of 'dateOfInsurance'
      if (!validFrom || !value) return true; // If either of the dates is not provided, return true
      const expirationDate = new Date(value);
      const issuanceDate = new Date(validFrom);
      return expirationDate >= issuanceDate;
    }),
    aircraftCategory: yup
          .string()
          .required("Aircraft category is required")
          .notOneOf(
            ["Aircraft Category"],
            'Aircraft category is required',
          ),
    addInfo: yup.string().required('Additional Info is required'),
    clientName: yup.string().required('Client is required*'),
    accountId: yup.string().required('Account ID is required*'),

})

const cardFormValidationSchema = () => {
  return yup.object().shape({
    amount: yup.string().nullable()
    .matches(amountWithCommaRegex, 'Amount must be valid number')
    .required('Amount is required'),
    card_number: yup.string().nullable()
      .matches(/^(\d{4} ){3}\d{4}$/, 'Card number is not valid')
      .required('Card number is required'),
    name_on_card: yup.string()
      .trim()
      .nullable()
      .matches(/\S/, 'Name on card is required')
      .required('Name on card is required'),
    expiry: expiryDateValidation(),
    security_code: yup.string()
      .nullable()
      .matches(/^\d{3,4}$/, 'CVV must be 3 or 4 digits')
      .required('CVV is required'),
    street_address: yup.string()
      .trim()
      .nullable()
      .matches(/\S/, 'Street address is required')
      .required('Street address is required'),
    city: yup.string()
      .trim()
      .nullable()
      .matches(/\S/, 'City is required')
      .required('City is required'),
    state: yup.string()
      .trim()
      .nullable()
      .matches(/\S/, 'State is required')
      .required('State is required'),
    zip_code: yup.string()
      .trim()
      .nullable()
      .matches(/\S/, 'ZIP code is required')
      .required('ZIP code is required'),
    country: yup.string()
      .nullable()
      .matches(/\S/, 'Country is required')
      .required('Country is required'),
  });
};

const existingCardSchema = ()=>{
  return yup.object().shape({
    amount_pay:yup.string().nullable()
    .matches(amountWithCommaRegex, 'Amount must be valid number')
    .required('Amount is required'),
    card_id:yup.number()
    .nullable()
    .required("Card is required")  
  })
}

const topupSchema = ()=>{
  return yup.object().shape({
    topupAmount:yup.number()
    .nullable()
    .typeError('Amount must be a number')
    .positive('Amount must be positive')
    .required('Amount is required'),
  })
}

const card25Schema = yup.object().shape({
  cardName:yup.string().nullable().required('Card Name is required'),
  price: yup.string().nullable()
  .matches(amountWithCommaRegex, 'Price must be valid price')
  .required('Price is required'),
  category: yup.string().nullable().required('Category is required').notOneOf(
    ["Category"],
    'Aircraft category is required',
  ),
  });

  const debitCardSchema = yup.object().shape({
    current_top_ups: yup.array().of(
      yup.object().shape({
        top_up: yup.string().nullable()
        .matches(amountWithCommaRegex, 'Top up must be valid number')
        .required('Top up is required')
      })
    )
  });
  const cardDescriptionSchema = yup.object().shape({
    title:yup.string().nullable().required('Title is required'),
    });

    const cvvSchema = yup.object().shape({
      security_code: yup.string()
       .nullable()
       .matches(/^\d{3,4}$/, 'CVV must be 3 or 4 digits*')
       .required('CVV is required*'),
      });

      const topupHourCardsSchema = ()=>{
        return yup.object().shape({
          hours:yup.number()
          .nullable()
          .typeError('Hours must be a number')
          .positive('Hours must be positive')
          .required('Hours are required')
          .min(0,"Hours must be 0 or positive"),
          minutes:yup.number()
          .nullable()
          .typeError('Minutes must be a number')
          .positive('Minutes must be positive')
          .required('Minutes are required')
          .min(0,"Hours must be 0 or positive")
        })
      }
      const cardNumberSchema = (mode:string) => {
        switch (mode) {
          case 'edit':
            return yup.string()
              .nullable()
              .matches(/^\d{4}$/, 'Only 4 digits are allowed in edit mode');
      
          case 'show':
            return yup.string()
              .nullable()
              .matches(/^(\d{4} ){3}\d{4}$/, 'Card number is not valid')
              .required('Card number is required');
      
          case 'add':
          default:
            return yup.string()
              .nullable()
              .matches(/^(\d{4} ){3}\d{4}$/, 'Card number is not valid')
              .required('Card number is required');
        }
      };
      const cardValidationSchema = (mode:string) => yup.object().shape({
        cardholderName: yup.string()
          .trim()
          .min(1, 'Cardholder Name cannot be empty*')
          .required('Cardholder Name is required*')
          .test('is-not-only-spaces', 'Cardholder Name cannot be only spaces', value => {
            return value && value.trim().length > 0;
          }),
        expiryDate: expiryDateValidation(), // Assuming this is defined elsewhere
        cardNumber: cardNumberSchema(mode),
        streetAddress: yup.string()
          .trim()
          .nullable()
          .matches(/\S/, 'Street address is required')
          .required('Street address is required'),
        city: yup.string()
          .trim()
          .nullable()
          .matches(/\S/, 'City is required')
          .required('City is required'),
        state: yup.string()
          .trim()
          .nullable()
          .matches(/\S/, 'State is required')
          .required('State is required'),
        zipCode: yup.string()
          .trim()
          .nullable()
          .matches(/\S/, 'ZIP code is required')
          .required('ZIP code is required'),
        country: yup.string()
          .nullable()
          .matches(/\S/, 'Country is required')
          .required('Country is required'),
      })
      const topupCustomCardSchema = ()=>{
        return yup.object().shape({
          trips:yup.number()
          .nullable()
          .typeError('No of Trips must be a number')
          .positive('No of Trips must be positive')
          .required('No of Trips is required'),
          price:yup.string().nullable()
          .matches(amountWithCommaRegex, 'Price must be valid number')
          .required('Price is required'),
        })
      }
  
      const amountValidationSchema = (maxAmount: string) => yup.string()
      .transform((value) => value.replace(/,/g, '')) 
      .matches(/^\d+(\.\d{1,2})?$/, 'Amount must be a valid number format')
      .test(
        'is-valid-number',
        'Amount must be a number',
        (value) => !isNaN(Number(value))
      )
      .test(
        'is-positive',
        'Amount must be positive',
        (value) => Number(value) > 0
      )
      .test(
        'max-amount',
        `Amount cannot be more than $${maxAmount}`,
        function (value) {
          const cleanedMaxAmount = Number(maxAmount.replace(/,/g, '')); // Remove commas from maxAmount
          return Number(value) <= cleanedMaxAmount;
        }
      )
      .required('Amount is required');

      const editCustomSkyCardSchema = yup.object().shape({
        from_airport: yup.object({
          id: createLocationValidation({
            fieldName: 'fromAirport',
            otherFieldName: 'toAirport',
            requiredMessage: 'From Location is required',
            notSameMessage: "From and To airport can't be the same",
          })
        }),
      
        to_airport: yup.object({
          id: createLocationValidation({
            fieldName: 'toAirport',
            otherFieldName: 'fromAirport',
            requiredMessage: 'To Location is required',
            notSameMessage: "From and To airport can't be the same",
          })
        }),
        aircraft_category: yup
          .string()
          .required("Aircraft category is required")
          .notOneOf(
            ["Aircraft Category"],
            'Aircraft category is required',
          ),
          expiry_date: yup.string()
          .nullable()
          .required("To Date is required")
      
      })

      const isFileResType = (value:any) => 
        typeof value === 'object' && 
        ('id' in value) && 
        ('file_name' in value) && 
        ('file_url' in value);

      const catalogueFormSchema = yup.object().shape({
        aircraft_name: yup.string()
          .nullable()
          .required('Aircraft name is required')
          .trim()
          .min(1, 'Aircraft name cannot be empty or just spaces')
          .matches(/.+/, 'Aircraft name cannot be only spaces'),
      
        aircraft_manufacturer: yup.string()
          .nullable()
          .required('Aircraft manufacturer is required')
          .trim()
          .min(1, 'Manufacturer name cannot be empty or just spaces')
          .matches(/.+/, 'Manufacturer name cannot be only spaces'),
      
        aircraft_category: yup.string()
          .required('Category is required')  
          .nullable()                        
          .notOneOf(['none'], 'Category is required'),
      
        aircraft_passenger: yup.number()
          .nullable()
          .required('Number of passengers is required')
          .typeError('Number of passengers must be a number')
          .positive('Number of passengers must be a positive number')
          .integer('Number of passengers must be an integer'),
      
        year_of_manufacturer: yup.number()
          .nullable()
          .required('Year of manufacturer is required')
          .typeError('Year of manufacturer must be a number')
          .integer('Year of manufacturer must be an integer')
          .min(1900, 'Year of manufacturer must be at least 1900')
          .max(new Date().getFullYear(), 'Year of manufacturer cannot be in the future'),
      
        year_of_refurb: yup.number()
          .nullable()
          .required('Year of refurbishment is required')
          .typeError('Year of refurbishment must be a number')
          .integer('Year of refurbishment must be an integer')
          .min(1900, 'Year of refurbishment must be at least 1900')
          .max(new Date().getFullYear(), 'Year of refurbishment cannot be in the future'),
          aircraft_images: yup.array()
          .of(
            yup.mixed()
              .required('Image is required')
              .test('fileSize', 'Image must be less than 6MB', (value) => {
                return value && value.size <= 6 * 1024 * 1024;
              })
              .test('fileFormat', 'Image must be in .jpg, .jpeg, or .png format', (value) => {
                return value && ['image/jpg', 'image/jpeg', 'image/png'].includes(value.type);
              })
          ),
          main_image: yup.mixed()
          .nullable()
          .required('Main image is required')
          .test('fileSize', 'File size should be less than 6MB', value => 
            value === null || 
            isFileResType(value) || 
            (value.size <= 6 * 1024 * 1024)
          )
          .test('fileFormat', 'Unsupported file format', value => 
            value === null || 
            isFileResType(value) || 
            ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type)
          )
      });

      const topupAmountSchema = yup.object().shape({
        amount:yup.string().nullable()
        .matches(amountWithCommaRegex, 'Top up must be valid number')
        .required('Top up is required')
      })



  export {catalogueFormSchema, amountValidationSchema, topupCustomCardSchema,cardValidationSchema,cvvSchema,topupSchema, expiryDateValidation, invoiceValidationSchema, commissionKeySchema, otpValidationSchema, emailValidationSchema, renderPasswordErrorBox, passwordTester, systemSettingsSchema,flightIteneraryValidationSchema, commission_schema, order_details_schema,payment_summary_validation_schema, contact_us_validation_schema, roundTripFormValidationSchema, oneWayFormValidationSchema, multiLegFormValidationSchema, oneWayFormSchema, roundTripSchema, multiLegSchema, contactUsValidationSchema,customSkyCardSchema,cardFormValidationSchema,existingCardSchema,card25Schema,debitCardSchema,cardDescriptionSchema,topupHourCardsSchema,editCustomSkyCardSchema,topupAmountSchema}

// Customizable Area End